import { useMemo } from 'react'
import { useTheme } from '@material-ui/styles'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTable, useFlexLayout, useFilters } from 'react-table'
import { useSticky } from 'react-table-sticky'
import { useOptions } from '../../hooks'

import './MUITable.scss'

function MUITable({
	columns,
	data,
	type,
	isMix = false,
	isMultiRaces = false,
	hiddenColumns = [],
}) {
	let barsHeight

	switch (type) {
		case 'rankingOV':
			barsHeight = isMultiRaces ? 164 : 128
			break
		case 'rankingRT':
			barsHeight = isMix ? 132 : 172
			break
		case 'departures':
			barsHeight = 48
			break
		case 'startList':
			barsHeight = 0
			break
		default:
			break
	}

	const useStyles = makeStyles(theme => ({
		tableContainer: {
			height: `calc(100vh - ${barsHeight}px - 64px)`,
		},
		row: {
			'&:nth-of-type(odd)': {
				'& td': {
					backgroundColor: theme.palette.tableBackgroundOdd,
				},
			},
			'&:nth-of-type(even)': {
				'& td': {
					backgroundColor: theme.palette.tableBackgroundEven,
				},
			},
		},
	}))
	const classes = useStyles()

	const defaultColumn = useMemo(
		() => ({
			Filter: DefaultColumnFilter,
		}),
		[]
	)

	function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
		return null
	}

	const filterTypes = useMemo(
		() => ({
			text: (rows, id, filterValue) => {
				return rows.filter(row => {
					const rowValue = row.values[id]
					return rowValue !== undefined
						? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
						: true
				})
			},
		}),
		[]
	)

	const { getTableProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: hiddenColumns,
			},
			defaultColumn,
			filterTypes,
		},
		useFlexLayout,
		useSticky,
		useFilters
	)
	const theme = useTheme()
	const background = theme.palette.tableBackground
	const { carNumber } = useOptions()

	return (
		<div className="table-root">
			<TableContainer className={classes.tableContainer}>
				<Table {...getTableProps()} className="table sticky {classes.odd}">
					<TableHead className="header">
						{headerGroups.map(headerGroup => (
							<TableRow {...headerGroup.getHeaderGroupProps()} className="trh">
								{headerGroup.headers.map(column => (
									<TableCell {...column.getHeaderProps()} className="th" bgcolor={background}>
										{column.render('Header')}
										<div>{column.canFilter ? column.render('Filter') : null}</div>
									</TableCell>
								))}
							</TableRow>
						))}
					</TableHead>
					<TableBody className="body">
						{rows.map((row, i) => {
							prepareRow(row)
							const trColor = row.values.raceNumber === Number(carNumber) ? 'trFluo' : 'tr'
							return (
								<TableRow {...row.getRowProps()} className={`${trColor} ${classes.row}`}>
									{row.cells.map(cell => {
										return (
											<TableCell {...cell.getCellProps()} className="td" bgcolor={background}>
												{cell.render('Cell')}
											</TableCell>
										)
									})}
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	)
}

export default MUITable
